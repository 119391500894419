import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Referral from './components/Referral';
import Homepage from './components/Homepage';

function App() {
  return (
    <BrowserRouter>
        <Switch>
            <Route exact path={"/"} component={Homepage}/>
            <Route path={"/:linkId"} component={Referral} />
        </Switch>
    </BrowserRouter>
  );
}

export default App;
