import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

function Referral() {

    const {linkId} = useParams();
    const [referralLink, setReferralLink] = useState("");
    // DEV
    // const linkToBackend = "http://localhost:5002/link/";

    // PROD
    const linkToBackend = "/link/";


    useEffect(() => {
        const getLinkData = async () => {
            if (linkId) {
                try {
                    console.log("link to backend is: ", linkToBackend);
                    const response = await fetch(linkToBackend + linkId);
                    if (!response.ok) {
                        const message = `An error occurred: ${response.statusText}`;
                        window.alert(message);
                    }

                    const linkData = await response.json();
                    if (linkData?.link) {
                        setReferralLink(linkData.link)
                    }
                } catch (e) {
                    console.log("fetch failed with error ", e);
                }
            }
        };

        getLinkData();

    }, [linkId])

    useEffect(() => {
        if (referralLink !== "") {
            window.open(referralLink, "_self");
        }
    }, [referralLink])

    return <div style={{backgroundColor: "black"}}/>
}

export default Referral;
